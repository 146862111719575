import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
};

function History({ color = theme.colors.gray['500'], ...props }: Props) {
  return (
    <svg fill="none" height="19" viewBox="0 0 22 19" width="22" {...props}>
      <path
        d="M12.26 1.00001C7.17003 0.860011 3.00003 4.95001 3.00003 10H1.21003C0.760031 10 0.540031 10.54 0.860031 10.85L3.65003 13.65C3.85003 13.85 4.16003 13.85 4.36003 13.65L7.15003 10.85C7.46003 10.54 7.24003 10 6.79003 10H5.00003C5.00003 6.10001 8.18003 2.95001 12.1 3.00001C15.82 3.05001 18.95 6.18001 19 9.90001C19.05 13.81 15.9 17 12 17C10.39 17 8.90003 16.45 7.72003 15.52C7.32003 15.21 6.76003 15.24 6.40003 15.6C5.98003 16.02 6.01003 16.73 6.48003 17.09C8.00003 18.29 9.91003 19 12 19C17.05 19 21.14 14.83 21 9.74001C20.87 5.05001 16.95 1.13001 12.26 1.00001ZM11.75 6.00001C11.34 6.00001 11 6.34001 11 6.75001V10.43C11 10.78 11.19 11.11 11.49 11.29L14.61 13.14C14.97 13.35 15.43 13.23 15.64 12.88C15.85 12.52 15.73 12.06 15.38 11.85L12.5 10.14V6.74001C12.5 6.34001 12.16 6.00001 11.75 6.00001Z"
        fill={color}
      />
    </svg>
  );
}

export default History;
