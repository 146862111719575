import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
};

function LiveRadio({ color = theme.colors.gray['500'], ...props }: Props) {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        clipRule="evenodd"
        d="M16.6289 18.1719C16.7863 18.3817 17.0805 18.4346 17.2925 18.2802C19.5396 16.6439 21 13.9925 21 11C21 6.02943 16.9707 2 12 2C7.0293 2 3 6.02943 3 11C3 13.8714 4.34455 16.4287 6.43853 18.0766C6.641 18.236 6.93119 18.1985 7.09807 18.0022C7.28858 17.7781 7.24301 17.4394 7.01313 17.256C5.1766 15.7901 4 13.5325 4 11C4 6.58173 7.58154 3 12 3C16.4185 3 20 6.58173 20 11C20 13.6402 18.7211 15.9817 16.7489 17.4387C16.516 17.6108 16.4552 17.9402 16.6289 18.1719ZM14.573 15.4306C14.8736 15.8314 15.4353 15.9452 15.8214 15.6259C17.1522 14.5254 18 12.8617 18 11C18 7.68629 15.3135 5 12 5C8.68652 5 6 7.68629 6 11C6 12.8159 6.8069 14.4434 8.08146 15.5436C8.44784 15.8599 8.99213 15.7741 9.30557 15.4053C9.70101 14.94 9.5606 14.2283 9.13452 13.7909C8.43244 13.0702 8 12.0856 8 11C8 8.79086 9.79102 7 12 7C14.209 7 16 8.79086 16 11C16 12.1179 15.5414 13.1286 14.8021 13.8544C14.3767 14.2722 14.2152 14.9536 14.573 15.4306Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M10.9503 17.1762C11.0453 17.0644 11.1845 17 11.3312 17H12.6688C12.8155 17 12.9547 17.0644 13.0497 17.1762L17.2998 22.1762C17.5758 22.5009 17.345 23 16.9188 23H7.08122C6.65501 23 6.42422 22.5009 6.70025 22.1762L10.9503 17.1762Z"
        fill={color}
      />
      <circle cx="12" cy="11" fill={color} r="2" />
      <rect fill={color} height="6" width="1" x="11.5" y="12" />
    </svg>
  );
}

export default LiveRadio;
