import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
};

function Pinterest({
  className = '',
  color = theme.colors.gray['600'],
  ...props
}: Props) {
  const dataTest = props['data-test'] || 'pinterest-svg';
  return (
    <svg
      className={className}
      data-test={dataTest}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.00158691C4.47737 0.00158691 0 4.47527 0 9.99336C0 14.2286 2.63374 17.847 6.35391 19.3026C6.26337 18.5131 6.1893 17.296 6.38683 16.4325C6.5679 15.6513 7.55556 11.4654 7.55556 11.4654C7.55556 11.4654 7.25926 10.8651 7.25926 9.98514C7.25926 8.59534 8.06584 7.55915 9.06996 7.55915C9.92593 7.55915 10.3374 8.2006 10.3374 8.9654C10.3374 9.82067 9.79424 11.1036 9.50617 12.296C9.26749 13.2911 10.0082 14.1052 10.9877 14.1052C12.7654 14.1052 14.1317 12.2302 14.1317 9.53284C14.1317 7.13974 12.4115 5.47034 9.95062 5.47034C7.10288 5.47034 5.4321 7.60027 5.4321 9.80422C5.4321 10.6595 5.76132 11.5805 6.17284 12.0822C6.25514 12.1809 6.26337 12.2713 6.23868 12.37C6.16461 12.6825 5.99177 13.3651 5.95885 13.5049C5.9177 13.6858 5.8107 13.7269 5.6214 13.6365C4.37037 13.0526 3.58848 11.2351 3.58848 9.7631C3.58848 6.61343 5.87654 3.71869 10.1975 3.71869C13.6626 3.71869 16.3621 6.1858 16.3621 9.49172C16.3621 12.9374 14.1893 15.7088 11.177 15.7088C10.1646 15.7088 9.20988 15.1825 8.88889 14.5575C8.88889 14.5575 8.38683 16.4654 8.26337 16.9342C8.04115 17.8059 7.4321 18.8914 7.02058 19.5575C7.95885 19.8453 8.9465 20.0016 9.98354 20.0016C15.5062 20.0016 19.9835 15.5279 19.9835 10.0098C20 4.47527 15.5226 0.00158691 10 0.00158691Z"
        fill={color}
      />
    </svg>
  );
}

export default Pinterest;
