import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  color?: string;
  className?: string;
  width?: string;
};

function TikTok({
  color = theme.colors.gray['600'],
  className = '',
  width = '18',
  ...props
}: Props) {
  const dataTest = props['data-test'] || 'tiktok-svg';
  return (
    <svg
      className={className}
      data-test={dataTest}
      fill="none"
      height="20"
      viewBox="0 0 18 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.75 4.91071C16.533 4.84196 15.3821 4.33443 14.5107 3.48214C14.0477 3.03475 13.6828 2.49596 13.4393 1.9C13.1912 1.30286 13.0696 0.66078 13.0821 0.0142822H9.58212V13.0179C9.58212 15.2143 8.27498 16.5107 6.65355 16.5107C6.26371 16.5197 5.87603 16.4504 5.51347 16.3068C5.15092 16.1633 4.82085 15.9484 4.54284 15.675C4.26438 15.4005 4.04235 15.0741 3.88926 14.7143C3.73838 14.3534 3.66069 13.9661 3.66069 13.575C3.66069 13.1838 3.73838 12.7966 3.88926 12.4357C4.04235 12.0759 4.26438 11.7495 4.54284 11.475C4.82085 11.2016 5.15092 10.9867 5.51347 10.8432C5.87603 10.6996 6.26371 10.6303 6.65355 10.6393C6.97624 10.6414 7.29625 10.6981 7.59998 10.8071V7.23571C7.28904 7.169 6.97153 7.13784 6.65355 7.14285C5.3821 7.14285 4.1392 7.51988 3.08203 8.22626C2.02485 8.93264 1.20089 9.93665 0.714324 11.1113C0.227761 12.286 0.100454 13.5786 0.348502 14.8256C0.59655 16.0726 1.20881 17.2181 2.10786 18.1171C3.00692 19.0162 4.15238 19.6284 5.3994 19.8765C6.64642 20.1245 7.93899 19.9972 9.11366 19.5107C10.2883 19.0241 11.2923 18.2001 11.9987 17.1429C12.7051 16.0858 13.0821 14.8429 13.0821 13.5714V6.78571C14.4633 7.70742 16.0896 8.1927 17.75 8.17857V4.91071Z"
        fill={color}
      />
    </svg>
  );
}

export default TikTok;
