import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
};

function Mic({ color = theme.colors.gray['500'], ...props }: Props) {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        d="M9.95848 20.0344C9.95848 19.3792 10.4875 18.8481 11.14 18.8481C11.7926 18.8481 12.3216 19.3792 12.3216 20.0344H14.6847C15.3372 20.0344 15.8662 20.5655 15.8662 21.2206V23H6.41383V21.2206C6.41383 20.5655 6.94283 20.0344 7.59538 20.0344H9.95848Z"
        fill={color}
      />
      <path
        d="M14.4573 1.54755C17.6587 2.40216 19.6162 5.50525 18.8291 8.47852L17.3291 14.1454C16.5422 17.1187 13.3086 18.8362 10.1072 17.9816C7.55371 17.3 5.79175 15.1883 5.58203 12.8529L8.82959 13.723C9.36304 13.866 9.91138 13.5494 10.0542 13.0159C10.1973 12.4825 9.88062 11.9341 9.34717 11.7912L5.79126 10.8384L6.30298 8.90488L9.86499 9.85927C10.3984 10.0022 10.9468 9.68563 11.0896 9.15216C11.2327 8.61869 10.916 8.07036 10.3826 7.92742L6.81494 6.97145L7.23511 5.3837C7.26782 5.26003 7.30493 5.13852 7.34595 5.01929L10.7334 5.92694C11.2668 6.06989 11.8152 5.7533 11.958 5.21984C12.1011 4.68637 11.7844 4.13803 11.251 3.99509L8.39868 3.23088C9.81006 1.65659 12.1421 0.929538 14.4573 1.54755Z"
        fill={color}
      />
    </svg>
  );
}

export default Mic;
