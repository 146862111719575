type Props = {
  'data-test'?: string;
};

function Pause(props: Props) {
  return (
    <svg {...props} viewBox="536 908 100 100">
      <rect height="94" width="18.2" x="548.6" y="910.9" />
      <rect height="94" width="18.2" x="605.2" y="910.9" />
    </svg>
  );
}

export default Pause;
