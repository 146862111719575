import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
};

function Artists({ color = theme.colors.gray['500'], ...props }: Props) {
  return (
    <svg fill="none" height="21" viewBox="0 0 20 21" width="20" {...props}>
      <path
        d="M17.4719 0.378185C17.558 0.366405 17.6513 0.409313 17.6874 0.506908L18.1432 1.73854C18.1635 1.79328 18.2067 1.83644 18.2614 1.8567L19.4931 2.31245C19.5295 2.32594 19.5583 2.34748 19.5795 2.37361C19.5947 2.3921 19.6059 2.41291 19.6132 2.43489C19.645 2.52992 19.6049 2.64625 19.4931 2.68757L18.2614 3.14332C18.2067 3.16359 18.1635 3.20674 18.1432 3.26149L17.6874 4.49311C17.623 4.66725 17.3769 4.66725 17.3124 4.49311L16.8566 3.26149C16.8364 3.20674 16.7932 3.16359 16.7385 3.14332L15.5068 2.68757C15.3327 2.62318 15.3327 2.37684 15.5068 2.31245L16.7385 1.8567C16.7932 1.83644 16.8364 1.79328 16.8566 1.73854L17.3124 0.506908C17.3408 0.43037 17.4042 0.387462 17.4719 0.378185Z"
        fill={color}
      />
      <path
        d="M4.18744 1.50691C4.12299 1.33277 3.87689 1.33277 3.81244 1.50691L2.9516 3.83338C2.94135 3.86085 2.92548 3.88545 2.90521 3.90565C2.89789 3.9131 2.88983 3.91993 2.88129 3.9261C2.86688 3.9366 2.85077 3.9452 2.83344 3.95155L0.506775 4.81245C0.332703 4.87684 0.332703 5.12318 0.506775 5.18757L2.83344 6.04847C2.88812 6.06874 2.93134 6.11189 2.9516 6.16664L3.81244 8.49311C3.87689 8.66725 4.12299 8.66725 4.18744 8.49311L5.04828 6.16664C5.06854 6.11189 5.11176 6.06874 5.16644 6.04847L7.4931 5.18757C7.66718 5.12318 7.66718 4.87684 7.4931 4.81245L5.16644 3.95155C5.11176 3.93129 5.06854 3.88813 5.04828 3.83338L4.18744 1.50691Z"
        fill={color}
      />
      <path
        d="M10.1472 5.92713C11.7092 4.36506 14.2419 4.36506 15.8039 5.92713C17.3661 7.48921 17.3661 10.0219 15.8039 11.584L14.3732 13.0146C12.8112 14.5767 10.2785 14.5767 8.71649 13.0146C7.15448 11.4525 7.15448 8.91987 8.71649 7.35774L10.1472 5.92713Z"
        fill={color}
      />
      <path
        d="M6.99701 11.9948C7.17767 12.6256 7.51678 13.2208 8.01361 13.7177C8.51044 14.2146 9.10565 14.5535 9.73651 14.7343C9.69379 14.8065 9.64618 14.8766 9.59369 14.9441C9.54291 15.0093 9.48773 15.072 9.42767 15.132L4.414 20.1458C3.633 20.9268 2.36664 20.9268 1.58563 20.1458C0.804626 19.3647 0.804626 18.0983 1.58563 17.3173L6.5993 12.3036C6.72137 12.1816 6.85492 12.0786 6.99701 11.9948Z"
        fill={color}
      />
      <path
        d="M17.0436 14.3809C16.9533 14.3614 16.8508 14.4034 16.8124 14.5069L16.2216 16.1035C16.2116 16.1305 16.196 16.1547 16.1762 16.1747C16.1559 16.1953 16.131 16.2114 16.1035 16.2216L14.5068 16.8125C14.3327 16.8768 14.3327 17.1232 14.5068 17.1876L16.1035 17.7784C16.1296 17.7881 16.153 17.803 16.1728 17.8219C16.1943 17.8425 16.2111 17.8679 16.2216 17.8966L16.8124 19.4931C16.8769 19.6672 17.123 19.6672 17.1874 19.4931L17.7783 17.8966C17.7985 17.8418 17.8417 17.7987 17.8964 17.7784L19.4931 17.1876C19.6672 17.1232 19.6672 16.8768 19.4931 16.8125L17.8964 16.2216C17.8417 16.2014 17.7985 16.1582 17.7783 16.1035L17.1874 14.5069C17.1613 14.4363 17.1052 14.3943 17.0436 14.3809Z"
        fill={color}
      />
    </svg>
  );
}

export default Artists;
